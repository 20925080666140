import { toQueryParams } from '@/helpers'
import { z } from 'zod'
import { paginatedApiCall } from './client'
import { type Pagination } from './shared'

export const Product = z.object({
  productName: z.string(),
  productCode: z.string()
})
export type Product = z.infer<typeof Product>

export const Carrier = z.object({
  carrierName: z.string(),
  carrierCode: z.string(),
  products: Product.array()
})
export type Carrier = z.infer<typeof Carrier>
export const Carriers = Carrier.array()
export type Carriers = z.infer<typeof Carriers>

export const CarrierFilter = z.object({})
export type CarrierFilter = z.infer<typeof CarrierFilter>

export const GetCarriers = (filter: CarrierFilter, pagination: Pagination) => {
  return paginatedApiCall(
    {
      path: `rest/api/accounts/carriers?${toQueryParams(filter, pagination).toString()}`,
      method: 'GET'
    },
    Carrier
  )
}
