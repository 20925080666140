import { ApiError, Pagination } from '@/api/parcelCore'
import {
  type Carrier,
  type CarrierFilter,
  type Carriers,
  GetCarriers
} from '@/api/parcelCore/carrier'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface CarriersStore {
  [id: string]: Carrier
}

export const useCarriersStore = defineStore('pc-carriers', () => {
  const store = ref<CarriersStore>({})
  const loading = ref(false)
  const error = ref<ApiError>()
  const total = ref<number>(0)
  const filter = ref<CarrierFilter>()
  const carriers = computed<Carriers>(() => Object.values(store.value))

  const fetchAll = async (filter: CarrierFilter, pagination: Pagination) => {
    loading.value = true
    error.value = undefined
    const result = await GetCarriers(filter, pagination)
    loading.value = false
    if ('status' in result) return (error.value = result)

    store.value = {}
    total.value = result.totalCount
    result.data.forEach((carrier) => {
      store.value[carrier.carrierCode] = carrier
    })
  }

  return {
    carriers,
    error,
    fetchAll,
    loading,
    total,
    filter
  }
})
