import type { Address, Delivery } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

const formatAddress = (address: Address) =>
  [
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
    address.street,
    address.postalCode,
    address.city
  ]
    .filter((s) => !!s)
    .join('\n')

export const deliveryToSuiteItemDetailsDetails = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>
): SuiteItemDetailsProps['details'] => {
  if (!delivery) return undefined

  const { t } = i18n

  return {
    columns: [
      {
        title: t('Office.ShipmentDetails.shipmentDetails'),
        items: [
          {
            label: t('Shipment.from'),
            value: formatAddress(delivery.from)
          }
        ]
      },
      {
        title: t('Office.ShipmentDetails.customerDetails'),
        items: [
          {
            label: t('Shipment.to'),
            value: formatAddress(delivery.to)
          }
        ]
      }
    ]
  }
}
