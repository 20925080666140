import type { PaginationOptions } from '@/api/shipcloud'
import type { PickupRequestFilter } from '@/api/shipcloud/pickupRequests'
import { toQueryParams } from '@/helpers'

export const GetPickupRequests = (
  filterOptions: PickupRequestFilter,
  pagination: PaginationOptions
) => ({
  path: `v1/pickup_requests?${toQueryParams(filterOptions, pagination).toString()}`,
  method: 'GET'
})

export const GetPickupRequest = (id: string) => ({
  path: `v1/pickup_requests/${id}`,
  method: 'GET'
})
