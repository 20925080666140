import { toQueryParams } from '@/helpers'
import { z } from 'zod'
import { Address } from './address'
import { apiCall, paginatedApiCall } from './client'
import { DeliveryItem } from './deliveryItem'
import { MILESTONES } from './event'
import { type Pagination } from './shared'

export const Delivery = z.object({
  id: z.string(),
  deliveryNumber: z.string(),
  clientCode: z.string(),
  clientName: z.string(),
  createdAt: z.string(),
  shippingDate: z.string(),
  instruction: z.string().optional(),
  comment: z.string().optional(),
  to: Address,
  from: Address,
  deliveryItems: DeliveryItem.array()
})
export type Delivery = z.infer<typeof Delivery>
export const Deliveries = z.array(Delivery)
export type Deliveries = z.infer<typeof Deliveries>

export const DeliveryFilter = z.object({
  carrierCode: z.string().optional(),
  createdAt: z.string().optional(),
  deliveryNumber: z.string().optional(),
  milestone: z.enum(MILESTONES).optional(),
  trackingNumber: z.string().optional()
})
export type DeliveryFilter = z.infer<typeof DeliveryFilter>

export const GetDeliveries = (filter: DeliveryFilter, pagination: Pagination) => {
  return paginatedApiCall(
    {
      path: `rest/api/accounts/deliveries?${toQueryParams(filter, pagination).toString()}`,
      method: 'GET'
    },
    Delivery
  )
}

export const GetDelivery = (id: string) =>
  apiCall({
    path: `rest/api/accounts/deliveries/${id}`,
    method: 'GET'
  })
