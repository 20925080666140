import type { AxiosInstance } from 'axios'
import axios from 'axios'

import { useAuthenticationV2Store } from '@/stores/authenticationV2'
import type { z } from 'zod'
import { ApiError, createPaginatedResponseSchema, type RestParams } from './shared'

export const client: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_PARCEL_CORE_API_HOST,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 35000, // shipcloud API timeout is 30s
  responseType: 'json'
})

client.interceptors.request.use(
  (config) => {
    const store = useAuthenticationV2Store()
    const token = store.token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

export const apiCall = async (restParams: RestParams) => {
  try {
    const { data, status } = await client(restParams.path, {
      method: restParams.method,
      data: restParams.data
    })
    return status == 200 ? data : { status }
  } catch (error: any) {
    return { status: 0 }
  }
}

export const paginatedApiCall = async <T extends z.ZodTypeAny>(
  restParams: RestParams,
  schema: T
) => {
  const paginatedSchema = createPaginatedResponseSchema(schema)
  const result = await apiCall(restParams)
  const parsed = paginatedSchema.safeParse(result)
  if (parsed.success) return parsed.data
  console.error(parsed)
  const error = ApiError.safeParse(result)
  if (error.success) return error.data
  return { status: 0 }
}
