import { useCredentialsStore } from '@/stores/credentials'

import { GetDefaultReturnsAddress, GetDefaultShippingAddress, type Address } from './address'
import { GetCarriers, type Carrier } from './carrier'
import { client } from './client'
import type { Me } from './me'
import { GetMe } from './me'
import {
  GetPickupRequest,
  GetPickupRequests,
  GetPickupRequestsResponse,
  PickupRequest,
  type GetPickupRequestsResponseT,
  type PickupRequestFilter
} from './pickupRequests'
import type { GetShipmentsResponse, NewShipment, Shipment, ShipmentFilter } from './shipment'
import { CreateShipment, DeleteShipment, GetShipment, GetShipments } from './shipment'
import { CreateShipmentQuote, type ShipmentQuote } from './shipment_quotes'
import {
  ActivateShippingRuleTree,
  CreateShippingRuleTree,
  CreateShippingRuleTreeResolution,
  GetShippingRuleTrees,
  type GetShippingRuleTreesResponse,
  type ShippingRuleTree,
  type ShippingRuleTreeFilterOptions,
  type ShippingRuleTreeResolutionRequest,
  type ShippingRuleTreeResolutionResponse
} from './shipping_rules'

export type ApiCallProps = {
  method: string
  path: string
}

export type PaginationOptions = {
  page: number
  per_page: number
}

export type ShipcloudApiError = { errors: string[]; status?: number; action?: 'delete' | 'get' }
export type ShipcloudApiProperty =
  | { property: string }
  | { model: string }
  | { property: string; model: string }

export function isShipcloudApiError(error: any): error is ShipcloudApiError {
  return (error as ShipcloudApiError)?.errors !== undefined
}

export const errorOn = (
  error: ShipcloudApiError | undefined,
  prop: ShipcloudApiProperty
): boolean => {
  if (!error) return false

  const property = 'property' in prop ? prop.property.replace('_', ' ') : ''
  const model = 'model' in prop ? prop.model : ''
  return error.errors.filter((e) => e.match(new RegExp(`${model}.*${property}`, 'i'))).length > 0
}

const formatException = (error: any): ShipcloudApiError => {
  const responseBody = error.response?.data
  const errors = isShipcloudApiError(responseBody) ? responseBody.errors : []
  const action = 'config' in error ? error.config.method : ''
  const status = error.status
  return { errors, status, action }
}

export class ShipcloudAPI {
  accessToken: string

  constructor() {
    const credentials = useCredentialsStore()
    this.accessToken = credentials.accessToken
  }

  async getMe(): Promise<Me | ShipcloudApiError> {
    try {
      const { data } = await client<Me>(GetMe.path, {
        method: GetMe.method
      })

      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getDefaultShippingAddress(): Promise<Address | ShipcloudApiError> {
    try {
      const { data } = await client<Address>(GetDefaultShippingAddress.path, {
        method: GetDefaultShippingAddress.method
      })

      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getDefaultReturnsAddress(): Promise<Address | ShipcloudApiError> {
    try {
      const { data } = await client<Address>(GetDefaultReturnsAddress.path, {
        method: GetDefaultReturnsAddress.method
      })

      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getCarriers(): Promise<Carrier[] | ShipcloudApiError> {
    try {
      const { data } = await client<Carrier[]>(GetCarriers.path, {
        method: GetCarriers.method
      })

      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getShipment(id: string): Promise<Shipment | ShipcloudApiError> {
    try {
      const getShipment = GetShipment(id)
      const { data } = await client<Shipment>(getShipment.path, {
        method: getShipment.method
      })

      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getShipments(
    filterOptions: ShipmentFilter,
    pagination: PaginationOptions
  ): Promise<GetShipmentsResponse | ShipcloudApiError> {
    try {
      const getShipments = GetShipments(filterOptions, pagination)
      const { headers, data } = await client<Record<string, Shipment[]>>(getShipments.path, {
        method: getShipments.method
      })

      const response: GetShipmentsResponse = {
        total: parseInt(headers.total) || 0,
        shipments: data?.shipments
      }

      return response
    } catch (error: any) {
      return formatException(error)
    }
  }

  async createShipment(shipment: NewShipment): Promise<Shipment | ShipcloudApiError> {
    try {
      const createShipment = CreateShipment(shipment)
      const { data } = await client<Shipment>(createShipment.path, {
        method: createShipment.method,
        data: createShipment.data
      })
      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async deleteShipment(id: string) {
    try {
      const deleteShipment = DeleteShipment(id)
      const { data } = await client<Shipment>(deleteShipment.path, {
        method: deleteShipment.method
      })

      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getShipmentQuote(shipment: NewShipment): Promise<ShipmentQuote | ShipcloudApiError> {
    try {
      const createShipment = CreateShipmentQuote(shipment)
      const { data } = await client<ShipmentQuote>(createShipment.path, {
        method: createShipment.method,
        data: createShipment.data
      })
      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getShippingRuleTrees(
    filterOptions: ShippingRuleTreeFilterOptions,
    pagination: PaginationOptions
  ): Promise<GetShippingRuleTreesResponse | ShipcloudApiError> {
    try {
      const getShippingRuleTrees = GetShippingRuleTrees(filterOptions, pagination)
      const { data } = await client<GetShippingRuleTreesResponse>(getShippingRuleTrees.path, {
        method: getShippingRuleTrees.method
      })

      return { shipping_rule_trees: data.shipping_rule_trees }
    } catch (error: any) {
      return formatException(error)
    }
  }

  async createShippingRuleTree(
    shippingRuleTree: ShippingRuleTree
  ): Promise<ShippingRuleTree | ShipcloudApiError> {
    try {
      const createShippingRuleTree = CreateShippingRuleTree(shippingRuleTree)
      const { data } = await client<ShippingRuleTree>(createShippingRuleTree.path, {
        method: createShippingRuleTree.method,
        data: createShippingRuleTree.data
      })
      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async activateShippingRuleTree(
    shippingRuleTree: ShippingRuleTree
  ): Promise<ShippingRuleTree | ShipcloudApiError> {
    try {
      const activateShippingRuleTree = ActivateShippingRuleTree(shippingRuleTree)
      const { data } = await client<ShippingRuleTree>(activateShippingRuleTree.path, {
        method: activateShippingRuleTree.method,
        data: '',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async createShippingRuleTreeResolution(
    shippingRuleTreeResolutionRequest: ShippingRuleTreeResolutionRequest
  ): Promise<ShippingRuleTreeResolutionResponse | ShipcloudApiError> {
    try {
      const request = CreateShippingRuleTreeResolution(shippingRuleTreeResolutionRequest)
      const { data } = await client<ShippingRuleTreeResolutionResponse | ShipcloudApiError>(
        request.path,
        {
          method: request.method,
          data: request.data
        }
      )
      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getPickupRequests(
    filterOptions: PickupRequestFilter,
    pagination: PaginationOptions
  ): Promise<GetPickupRequestsResponseT | ShipcloudApiError> {
    try {
      const request = GetPickupRequests(filterOptions, pagination)
      const { headers, data } = await client<Record<string, PickupRequest[]>>(request.path, {
        method: request.method
      })
      return GetPickupRequestsResponse.parse({
        total: parseInt(headers.total) || 0,
        pickupRequests: data?.pickup_requests
      })
    } catch (error: any) {
      return formatException(error)
    }
  }

  async getPickupRequest(id: string): Promise<PickupRequest | ShipcloudApiError> {
    try {
      const getPickupRequest = GetPickupRequest(id)
      const { data } = await client<PickupRequest>(getPickupRequest.path, {
        method: getPickupRequest.method
      })

      return data
    } catch (error: any) {
      return formatException(error)
    }
  }

  handleErrors(response: Response) {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response
  }
}
