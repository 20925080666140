import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import { isShipcloudApiError, ShipcloudAPI } from '@/api/shipcloud'
import type { Carrier } from '@/api/shipcloud/carrier'
import type { SuiteSelectOption } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

interface CarriersStore {
  [id: string]: Carrier
}

export const useCarriersStore = defineStore('carriers', () => {
  const shipcloudApi = new ShipcloudAPI()
  const { t } = useI18n()

  const carriers: Ref<CarriersStore> = ref({})
  const services: Ref<Set<string>> = ref(new Set())
  const loading = ref(false)

  const fetchCarriers = () => {
    loading.value = true
    return shipcloudApi.getCarriers().then((response) => {
      if (!isShipcloudApiError(response)) {
        carriers.value = {}

        response.forEach((carrier) => {
          carriers.value[carrier.name] = carrier
          carrier.services.forEach((serviceName) => {
            services.value.add(serviceName)
          })
        })
      }
      loading.value = false
    })
  }

  const carrierOptions = computed(() => {
    const options: SuiteSelectOption[] = []

    if (Object.keys(carriers).length) {
      Object.values(carriers.value).forEach((carrier) =>
        options.push({ label: carrier.display_name, value: carrier.name })
      )
      options.sort((a, b) => a.label.localeCompare(b.label))
    }

    return options
  })

  const serviceOptionsPerCarrier = computed(() => {
    const optionsPerCarrier: Record<string, SuiteSelectOption[]> = {}

    if (Object.keys(carriers).length) {
      Object.values(carriers.value).forEach((carrier) => {
        const options: SuiteSelectOption[] = carrier.services.map((service) => ({
          label: t(`Shipment.services.${service}`),
          value: service
        }))
        options.sort((a, b) => a.label.localeCompare(b.label))
        Object.assign(optionsPerCarrier, { [carrier.name]: options })
      })
    }

    return optionsPerCarrier
  })

  const carrierSupportsReturns = (carrier: string): boolean => {
    const services = serviceOptionsPerCarrier.value[carrier]
    return services && !!services.find((service) => service.value == 'returns')
  }

  const carrierSupportsPickupRequests = (carrier: string): boolean =>
    ['hermes', 'dpd', 'ups'].includes(carrier)

  const hasItems = computed(() => Object.keys(carriers.value).length > 0)

  return {
    carrierOptions,
    carrierSupportsPickupRequests,
    carrierSupportsReturns,
    fetchCarriers,
    hasItems,
    loading,
    carriers,
    services,
    serviceOptionsPerCarrier
  }
})
