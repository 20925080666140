import { useAuthenticationV1 } from '@/composables/useAuthenticationV1'
import { useAuthenticationV2 } from '@/composables/useAuthenticationV2'
import { gtm } from '@/helpers'
import { useAuthenticationStore } from '@/stores/authentication'
import type { NavigationFailure, RouteLocationNormalizedGeneric } from 'vue-router'

const authFlow = async (
  checkAuthentication: (code: string) => Promise<void>,
  checkAuthorization: () => Promise<boolean>,
  code: string
): Promise<boolean> => {
  const authenticationStore = useAuthenticationStore()
  await checkAuthentication(code)
  if (authenticationStore.recheck()) {
    const authorized = await checkAuthorization()
    if (authorized) authenticationStore.checked()
    else return false
  }
  return true
}

const isUnknownRoute = (to: RouteLocationNormalizedGeneric): boolean => !to.name

const isErrorRoute = (to: RouteLocationNormalizedGeneric): boolean =>
  to.name === 'authentication-error'

export const beforeEach = async (to: RouteLocationNormalizedGeneric) => {
  const authenticationStore = useAuthenticationStore()
  authenticationStore.checking = true
  if (to.path === '/v1') authenticationStore.authenticationMode = 'v1'
  if (to.path === '/v2') authenticationStore.authenticationMode = 'v2'

  const code = 'code' in to.query ? String(to.query.code) : ''
  if (authenticationStore.authenticationMode === 'v2') {
    const { checkAuthentication, checkAuthorization } = useAuthenticationV2()
    const authorized = await authFlow(checkAuthentication, checkAuthorization, code)
    authenticationStore.checking = false
    if (authorized && (isUnknownRoute(to) || isErrorRoute(to))) return { name: 'suite-entry-v2' }
    if (!authorized && !isErrorRoute(to)) return { name: 'authentication-error' }
  } else if (authenticationStore.authenticationMode === 'v1') {
    const { checkAuthentication, checkAuthorization } = useAuthenticationV1()
    const authorized = await authFlow(checkAuthentication, checkAuthorization, code)
    authenticationStore.checking = false
    if (authorized && (isUnknownRoute(to) || isErrorRoute(to))) return { name: 'suite-entry' }
    if (!authorized && !isErrorRoute(to)) return { name: 'authentication-error' }
  }
}

export const afterEach = (
  to: RouteLocationNormalizedGeneric,
  _: RouteLocationNormalizedGeneric,
  failure: void | NavigationFailure | undefined
) => {
  if (!failure) gtm.trackPage(to.path)
}
