<script setup lang="ts">
import { SuiteLoader } from '@shipcloud/suite-components'
import { RouterView } from 'vue-router'
import { useAuthenticationStore } from './stores/authentication'

const authenticationStore = useAuthenticationStore()
</script>

<template>
  <SuiteLoader page :loading="authenticationStore.checking">
    <RouterView />
  </SuiteLoader>
</template>
