import type { DeliveryFilter } from '@/api/parcelCore'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDeliveriesFilterStore = defineStore(
  'deliveriesFilter',
  () => {
    const filter = ref<DeliveryFilter>({})

    return {
      filter
    }
  },
  { persist: true }
)
