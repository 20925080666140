import type { Delivery } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'
import { deliveryToSuiteItemDetailsTableBody } from './body'
import { deliveryToSuiteItemDetailsTableHead } from './head'

export const deliveryToSuiteItemDetailsTable = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>
) => {
  if (!delivery?.deliveryItems?.length) return undefined

  return {
    body: deliveryToSuiteItemDetailsTableBody(delivery, i18n),
    head: deliveryToSuiteItemDetailsTableHead(delivery, i18n)
  } as SuiteItemDetailsProps['table']
}
