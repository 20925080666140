import type { Delivery } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

export const deliveryToSuiteItemDetailsSummary = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>
): SuiteItemDetailsProps['summary'] => {
  if (!delivery) return undefined

  const { d, t } = i18n

  return {
    items: [
      {
        label: t('Shipment.id'),
        value: delivery.deliveryNumber
      },
      {
        label: t('Shipment.created_at'),
        value: d(delivery.createdAt)
      }
    ]
  }
}
