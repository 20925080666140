import type { Address, Deliveries, Delivery, Shipment } from '@/api/parcelCore'
import { SuiteButton, SuiteFlag, type SuiteTableNestedRow } from '@shipcloud/suite-components'
import { markRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'
import { milestoneCaption } from './milestoneCaption'

const rawComponentSuiteButton = markRaw(SuiteButton)
const rawComponentSuiteFlag = markRaw(SuiteFlag)

const formatDate = (date: string, i18n: ReturnType<typeof useI18n>): string => {
  try {
    return i18n.d(date)
  } catch {
    return ''
  }
}

const formatRecipient = (address: Address): string =>
  [address.addressLine1, address.postalCode, address.city].join(' ')

const getRowCells = (
  delivery: Delivery,
  lastShipment: Shipment,
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>,
  isChild: boolean = false
) => {
  return [
    isChild
      ? {
          content: lastShipment.shipmentItem?.trackingNumber || '',
          class: 'ps-6'
        }
      : lastShipment.shipmentItem?.trackingNumber || '',
    milestoneCaption(lastShipment.shipmentItem?.events?.[0], i18n),
    lastShipment.carrierName,
    lastShipment.productName,
    formatDate(delivery.createdAt, i18n),
    formatRecipient(delivery.to),
    {
      props: { country: delivery.to.countryCode },
      component: rawComponentSuiteFlag
    },
    {
      component: rawComponentSuiteButton,
      props: {
        icon: 'ZoomIn',
        onClick: () =>
          router.push({ name: 'parcel-core-shipment-details', params: { id: delivery.id } }),
        'data-identifier': 'details-link'
      }
    }
  ]
}

/**
 * Build the SuiteTableNested body object for the Deliveries table
 * @see https://app.shortcut.com/shipcloud/epic/34030#activity-37791
 */
export const deliveriesToSuiteTableNestedBody = (
  deliveries: Deliveries,
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>
) => {
  /* istanbul ignore next -- @preserve */
  if (!deliveries.length) return undefined

  const { t } = i18n

  return deliveries.reduce((acc, delivery) => {
    const { deliveryItems } = delivery

    /* istanbul ignore next -- @preserve */
    if (!deliveryItems.length) return acc

    let cells: SuiteTableNestedRow['cells']
    let children: SuiteTableNestedRow['children']

    deliveryItems.forEach((deliveryItem) => {
      const shipments = deliveryItem.shipments.filter((s) =>
        ['LastMile', 'DirectShipping'].includes(s.transportStepKind as string)
      )

      /* istanbul ignore next -- @preserve */
      if (!shipments.length) return

      if (!cells) {
        cells = getRowCells(delivery, shipments[0], i18n, router)
      } else {
        cells[0] = t('ParcelCore.multipleDelivery')
        children ??= []
        children.push({ cells: getRowCells(delivery, shipments[0], i18n, router, true) })
      }

      acc.push({
        attrs: { class: 'hover:suite-bg-blue-200' },
        cells,
        children
      })
    })

    return acc
  }, [] as SuiteTableNestedRow[])
}
