import { type PickupRequest } from '@/api/shipcloud/pickupRequests'
import type { Shipment, TrackingStatus } from '@/api/shipcloud/shipment'
import { addressLines, shipmentStatus, shortId, trackingStatusColors } from '@/helpers'
import type { useShipmentsStore } from '@/stores/shipments'
import { StatusCaption, SuiteButton, type SuiteItemDetailsProps } from '@shipcloud/suite-components'
import { computed, markRaw, ref } from 'vue'
import type { useI18n } from 'vue-i18n'
import { RouterLink, type useRouter } from 'vue-router'

export type ShipmentTableRow = any[]
export type ShipmentTableRows = ShipmentTableRow[]

export const usePickupRequestDetails = (
  shipmentsStore: ReturnType<typeof useShipmentsStore>,
  i18n: typeof useI18n,
  router: ReturnType<typeof useRouter>
) => {
  const { t, d } = i18n()
  const pickupRequest = ref<PickupRequest>()
  const shipmentTableRows = ref<ShipmentTableRows>([])

  const queryShipmentTableRows = (pickupRequest: PickupRequest) => {
    Object.values(pickupRequest.shipments).forEach(async (shipmentId) => {
      const { id } = shipmentId
      await shipmentsStore.fetchShipment(id)
      const shipment = shipmentsStore.shipmentById(id)
      if (shipment) {
        shipmentTableRows.value.push(shipmentToTableRow(shipment))
      }
    })
  }
  const shipmentToTableRow = (shipment: Shipment): ShipmentTableRow => {
    const status = shipmentStatus(shipment)
    const to = { name: 'shipment-details', params: { id: shipment.id } }
    return [
      {
        component: markRaw(RouterLink),
        content: shipment.carrier_tracking_no,
        props: { to }
      },
      {
        component: markRaw(StatusCaption),
        content: t(`TrackingEvent.status.${status}`),
        props: { color: trackingStatusColors(status as TrackingStatus) }
      },
      {
        component: markRaw(SuiteButton),
        class: 'flex justify-end',
        props: {
          icon: 'ZoomIn',
          'data-identifier': 'details-link',
          onClick: () => router.push(to)
        }
      }
    ]
  }
  const formatTimeslot = (timestamp: string) => {
    try {
      return d(timestamp, 'long')
    } catch {
      return timestamp
    }
  }
  const buildSummary = (pickupRequest: PickupRequest) => {
    const items = [
      {
        label: t('Office.PickupRequestDetails.id'),
        value: shortId(pickupRequest.id)
      },
      {
        label: t('Carrier.caption'),
        value: t(`Shipment.carriers.${pickupRequest.carrier}`, pickupRequest.carrier)
      },
      {
        label: t('Office.PickupRequestDetails.carrierPickupNumber'),
        value: pickupRequest.carrier_pickup_number
      },
      {
        label: t('Office.PickupRequestDetails.shipments'),
        value: pickupRequest.shipments.length.toString()
      }
    ]
    return { items }
  }
  const buildDetails = (pickupRequest: PickupRequest) => {
    const columns = [
      {
        title: t('Office.PickupRequestDetails.detailsTitle'),
        items: [
          {
            label: t('Office.PickupRequestDetails.pickupAddress'),
            value: addressLines(pickupRequest.pickup_address).join('\n')
          }
        ]
      }
    ]
    if (pickupRequest.pickup_time) {
      columns.push({
        title: t('Office.PickupRequestDetails.pickupTime'),
        items: [
          {
            label: t('Office.PickupRequestDetails.pickupTimeEarliest'),
            value: formatTimeslot(pickupRequest.pickup_time.earliest)
          },
          {
            label: t('Office.PickupRequestDetails.pickupTimeLatest'),
            value: formatTimeslot(pickupRequest.pickup_time.latest)
          }
        ]
      })
    }
    return { columns }
  }
  const buildDivider = () => {
    const text = t('Office.PickupRequestDetails.shipments')
    const icon = text[0].toUpperCase()
    return { icon, text }
  }
  const buildPickupRequestDetails = (currentPickupRequest: PickupRequest) => {
    pickupRequest.value = currentPickupRequest
    queryShipmentTableRows(currentPickupRequest)
  }
  const buildShipmentsTable = computed(() => {
    if (!shipmentTableRows.value.length) return

    const head = [
      {
        cells: [t('Package.carrier_tracking_no'), t('Package.status'), '']
      }
    ]
    const body = shipmentTableRows.value.map((row) => {
      return { cells: row }
    })
    return { head, body, noAlternatingRows: false }
  })
  const suiteItemDetailsProps = computed(() => {
    if (!pickupRequest.value) return undefined

    return {
      summary: buildSummary(pickupRequest.value),
      details: buildDetails(pickupRequest.value),
      divider: buildDivider(),
      table: buildShipmentsTable.value
    } as SuiteItemDetailsProps
  })

  return {
    buildPickupRequestDetails,
    suiteItemDetailsProps
  }
}
