import type { SuiteTableProps } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

export const deliveriesToSuiteTableNestedHead = (
  i18n: ReturnType<typeof useI18n>
): SuiteTableProps['head'] => {
  const { t } = i18n
  return [
    {
      cells: [
        t('Package.carrier_tracking_no'),
        t('Package.status'),
        t('Shipment.carrier'),
        t('Shipment.service'),
        t('Shipment.created_at'),
        t('Office.ShipmentsDashboard.recipient'),
        t('Office.ShipmentsDashboard.shipToCountry'),
        ''
      ]
    }
  ]
}
