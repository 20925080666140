import { MILESTONES } from '@/api/parcelCore'
import { useCarriersStore } from '@/stores/parcelCore/carriers'
import { type SuiteFilterProps, type SuiteSelectOption } from '@shipcloud/suite-components'
import { computed, type ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'

export const useDeliveriesFilter = (): ComputedRef<SuiteFilterProps> => {
  const { t } = useI18n()
  const carriersStore = useCarriersStore()
  const milestoneFilterOptions = MILESTONES.map((milestone) => {
    return { label: milestone, value: milestone }
  })
  const carrierFilterOptions = computed<SuiteSelectOption[]>(() => {
    return carriersStore.carriers.map((carrier) => {
      return { label: carrier.carrierName, value: carrier.carrierCode }
    })
  })
  if (carrierFilterOptions.value.length == 0) {
    carriersStore.fetchAll({}, { pageNumber: 1, pageSize: 50 })
  }
  const filters = computed<SuiteFilterProps['filters']>(() => {
    return {
      trackingNumber: {
        label: t('Package.carrier_tracking_no'),
        placeholder: t('Package.carrier_tracking_no'),
        type: 'text'
      },
      deliveryNumber: {
        label: t('Shipment.reference_number'),
        placeholder: t('Shipment.reference_number'),
        type: 'text'
      },
      milestone: {
        label: t('Package.status'),
        type: 'select',
        options: milestoneFilterOptions
      },
      carrierCode: {
        label: t('Shipment.carrier'),
        type: 'select',
        options: carrierFilterOptions.value
      },
      createdAt: {
        label: t('Shipment.created_at'),
        type: 'date'
      }
    }
  })
  return computed(() => {
    return {
      filters: filters.value,
      strAddFilterSelectionPlaceholder: t('Office.ShipmentsDashboard.filterSelectionPlaceholder'),
      strButtonAddFilter: t('Office.ShipmentsDashboard.filterAddButton'),
      strButtonAddFilterCancelAdd: t('Office.ShipmentsDashboard.filterCancelAddButton'),
      strButtonAddFilterCancelEdit: t('Office.ShipmentsDashboard.filterCancelEditButton'),
      strFilterToAddLabelFilter: t('Office.ShipmentsDashboard.filterLabel'),
      strFilterToAddLabelValue: t('Office.ShipmentsDashboard.filterValueLabel'),
      strNoFiltersSet: t('Office.ShipmentsDashboard.filterEmptyLabel'),
      strSetFilterButton: t('Office.ShipmentsDashboard.filterSetButton')
    }
  })
}
