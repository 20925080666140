import { StatusColorEnum } from '@shipcloud/suite-components'

import type { Address } from '@/api/shipcloud/address'
import {
  Shipment,
  TrackingStatus,
  type Package,
  type TrackingEvent
} from '@/api/shipcloud/shipment'
import en from '@/locales/en.json'

export * as gtm from './gtm'

export const appHost = import.meta.env.VITE_SEND_CORE_APP_HOST

export const carrierDefined = (name: string): boolean => {
  return Object.keys(en.Shipment.carriers).includes(name)
}

export const withoutNullableKeys = (params: Record<string, any>) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === undefined || params[key] === null || params[key] === '') {
      delete params[key]
    }
  })
  return params
}

export const toQueryParams = (
  filterOptions: Record<string, any>,
  pagination: Record<string, any>
) => {
  return new URLSearchParams(
    withoutNullableKeys({ ...withoutNullableKeys(filterOptions), ...pagination })
  )
}

export const shortId = (id: string): string => {
  return [id.substring(0, 5), id.substring(5, 10)].join('-').toUpperCase()
}

export const stringify = (value: any): string => {
  if (value === null) return ''
  return String(value)
}

export const localizeURL = (locale: string, url = appHost): string => {
  return new URL(locale, url).toString()
}

export const compact = <Type>(array: Type[]): Type[] => array.filter(Boolean)

export function assertExists<A>(value: A | null | undefined): A | boolean {
  if (value != null) {
    return value
  }

  return false
}

export const pick = <T extends {}, K extends keyof T>(obj: T, keys: string[]) => {
  return Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key as keyof T]])
  ) as Pick<T, K>
}

export const addressLines = (address: Address, keys?: string[]): string[] => {
  if (!assertExists(address)) return []

  if (keys?.length) {
    address = pick(address, keys)
  }

  return compact([
    address.company,
    compact([address.first_name, address.last_name]).join(' '),
    address.care_of,
    compact([address.street, address.street_no]).join(' '),
    `${compact([address.country, address.zip_code]).join('-')} ${compact([address.city])}`.trim()
  ]) as string[]
}

export const lastTrackingEvent = (pkg: Package): TrackingEvent | undefined => {
  if (pkg?.tracking_events?.length) return pkg.tracking_events[pkg.tracking_events.length - 1]
}

export const shipmentStatus = (shipment: Shipment): string => {
  if (shipment.packages.length == 0) return 'no_status'
  else if (shipment.packages.length > 1) return 'multicolli'

  const trackingEvent = lastTrackingEvent(shipment.packages[0])
  return TrackingStatus.safeParse(trackingEvent?.status).data || 'no_status'
}

export const trackingStatusColors = (status: TrackingStatus): StatusColorEnum | undefined => {
  switch (status) {
    case 'awaits_pickup_by_receiver':
    case 'canceled':
    case 'delayed':
    case 'label_created':
    case 'notification':
    case 'out_for_delivery':
    case 'picked_up':
    case 'ignored':
    case 'transit':
      return StatusColorEnum.BLACK

    case 'delivered':
      return StatusColorEnum.GREEN

    case 'destroyed':
    case 'exception':
    case 'not_delivered':
      return StatusColorEnum.RED
    default:
      return undefined
  }
}

export const weight = (value: string, unit = 'kg'): string => {
  return `${value} ${unit}`
}

export const showWipFeatures = () => {
  return import.meta.env.MODE === 'staging'
}

/**
 * Javascript's missing sleep function.
 * Handy to throttle API calls during development.
 */
export const sleep = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms))
