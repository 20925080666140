import type { Delivery } from '@/api/parcelCore'
import type { useI18n } from 'vue-i18n'

export const deliveryToSuiteItemDetailsTableHead = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>
) => {
  const { t } = i18n

  return [
    {
      cells: [
        t('Package.carrier_tracking_no'),
        t('Package.dimensions'),
        t('Package.weight'),
        t('Package.package_type'),
        t('Package.status')
      ]
    }
  ]
}
