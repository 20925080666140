import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import type { PaginationOptions, ShipcloudApiError } from '@/api/shipcloud'
import { ShipcloudAPI, isShipcloudApiError } from '@/api/shipcloud'
import type { PickupRequest, PickupRequestFilter } from '@/api/shipcloud/pickupRequests'

interface PickupRequestsStore {
  [id: string]: PickupRequest
}

export const usePickupRequestsStore = defineStore('pickupRequests', () => {
  const shipcloudApi = new ShipcloudAPI()
  const pickupRequests: Ref<PickupRequestsStore> = ref({})
  const pickupRequestsTotal = ref(0)
  const loading = ref(false)
  const saving = ref(false)
  const saveSuccess = ref(false)
  const error: Ref<ShipcloudApiError | undefined> = ref()

  const fetchPickupRequests = async (
    filterOptions: PickupRequestFilter,
    paginationOptions: PaginationOptions
  ) => {
    loading.value = true
    return shipcloudApi.getPickupRequests(filterOptions, paginationOptions).then((response) => {
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        pickupRequests.value = {}
        pickupRequestsTotal.value = response.total
        response.pickupRequests.forEach((pickupRequest) => {
          pickupRequests.value[pickupRequest.id] = pickupRequest
        })
        error.value = undefined
      }
      loading.value = false
    })
  }

  const pickupRequestById = computed(() => {
    return (id: string) => {
      return pickupRequests.value[id]
    }
  })

  const fetchPickupRequest = async (id: string) => {
    loading.value = true
    return shipcloudApi.getPickupRequest(id).then((response) => {
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        pickupRequests.value[response.id] = response
        error.value = undefined
      }
      loading.value = false
    })
  }

  return {
    pickupRequestById,
    fetchPickupRequest,
    fetchPickupRequests,
    pickupRequests,
    pickupRequestsTotal,
    loading,
    saving,
    saveSuccess,
    error
  }
})
