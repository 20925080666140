import type { ShipmentFilter } from '@/api/shipcloud/shipment'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useShipmentFilterStore = defineStore(
  'shipmentFilter',
  () => {
    const filter = ref<ShipmentFilter>({})

    return {
      filter
    }
  },
  { persist: true }
)
