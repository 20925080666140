import type { Delivery } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'
import { deliveryToSuiteItemDetailsDetails } from './details'
import { deliveryToSuiteItemDetailsSummary } from './summary'
import { deliveryToSuiteItemDetailsTable } from './table'

export const deliveryToSuiteItemDetails = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>
) => {
  if (!delivery) return undefined

  const { t } = i18n

  const details = deliveryToSuiteItemDetailsDetails(delivery, i18n)
  const divider: SuiteItemDetailsProps['divider'] = {
    icon: t('Office.ShipmentDetails.packages')[0].toUpperCase(),
    text: t('Office.ShipmentDetails.packages')
  }
  const summary = deliveryToSuiteItemDetailsSummary(delivery, i18n)
  const table = deliveryToSuiteItemDetailsTable(delivery, i18n)

  return {
    details,
    divider,
    summary,
    table
  } as SuiteItemDetailsProps
}
