<script setup lang="ts">
import type { ShippingRule } from '@/api/shipcloud/shipping_rules'
import { useCarriersStore } from '@/stores/carriers'
import { SuiteButton, SuiteSelect, type SuiteSelectOption } from '@shipcloud/suite-components'
import { computed, watchEffect } from 'vue'

withDefaults(
  defineProps<{
    strPlaceholderCarriers?: string
    strPlaceholderServices?: string
  }>(),
  {
    strPlaceholderCarriers: 'Select a carrier',
    strPlaceholderServices: 'Select a service'
  }
)

const emit = defineEmits<{
  (e: 'delete'): unknown
}>()

const output = defineModel<ShippingRule['output'][0]>('output', {
  default: () => ({ carrier: '', service: '' })
})

/*
  TODO In order to move this component to the component library,
  the store link should be severed and these values should be given in another way.
  Either through props or provide/inject.
*/
const carriersStore = useCarriersStore()
if (!carriersStore.hasItems) carriersStore.fetchCarriers()

const carrierItems = computed(() =>
  Object.values(carriersStore.carriers).map(
    (carrier): SuiteSelectOption<string> => ({
      label: carrier.display_name,
      value: carrier.name
    })
  )
)

const serviceItems = computed(() =>
  (carriersStore.carriers?.[output.value.carrier]?.services || []).map(
    (service): SuiteSelectOption<string> => ({ label: service, value: service })
  )
)

// If output.carrier not set, and carrierItems are available, force the first carrierItems entry
watchEffect(
  () =>
    !output.value.carrier &&
    carrierItems.value?.[0]?.value &&
    (output.value.carrier ??= carrierItems.value[0].value)
)

// If output.service not set, and serviceItems are available, force the first serviceItems entry
watchEffect(
  () =>
    !output.value.service &&
    serviceItems.value?.[0]?.value &&
    (output.value.service = serviceItems.value[0].value)
)
</script>

<template>
  <div class="grid grid-cols-[1fr_1fr_auto] items-center gap-2">
    <div>
      <SuiteSelect
        v-if="carrierItems.length"
        v-model="output.carrier"
        :options="carrierItems"
        :placeholder="strPlaceholderCarriers"
        class="w-full"
      />
    </div>
    <div>
      <SuiteSelect
        v-if="serviceItems.length"
        v-model="output.service"
        :options="serviceItems"
        :placeholder="strPlaceholderServices"
        class="w-full"
      />
    </div>
    <div class="flex gap-2">
      <SuiteButton icon="Delete2" @click="emit('delete')" class="h-8 w-8 px-1" />
    </div>
  </div>
</template>
