/* istanbul ignore file -- @preserve */
import type { Event } from '@/api/parcelCore'
import { StatusCaption, StatusColorEnum } from '@shipcloud/suite-components'
import { markRaw } from 'vue'
import { useI18n } from 'vue-i18n'

const rawComponentStatusCaption = markRaw(StatusCaption)

export const milestoneCaption = (event: Event | undefined, i18n: ReturnType<typeof useI18n>) => {
  const { t } = i18n
  switch ((event || {})?.milestone) {
    case 'Cancel':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.cancel'),
        props: { color: StatusColorEnum.RED }
      }
    case 'Delivered':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.delivered'),
        props: { color: StatusColorEnum.GREEN }
      }
    case 'Exception':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.exception'),
        props: { color: StatusColorEnum.RED }
      }
    case 'HUBIn':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.hubIn'),
        props: { color: StatusColorEnum.BLACK }
      }
    case 'InTransit':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.inTransit'),
        props: { color: StatusColorEnum.BLACK }
      }
    case 'OutForDelivery':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.outForDelivery'),
        props: { color: StatusColorEnum.BLACK }
      }
    case 'Ready':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.ready'),
        props: { color: StatusColorEnum.BLACK }
      }
    case 'Return':
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.return'),
        props: { color: StatusColorEnum.RED }
      }
    default:
      return {
        component: rawComponentStatusCaption,
        content: t('Milestone.none'),
        props: { color: StatusColorEnum.BLACK }
      }
  }
}
